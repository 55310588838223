import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import GoogleAdsense from "../components/GoogleAdsense"

export default function Poet({ data }) {
  const { contentfulPoet, allContentfulPoem } = data

  return (
    <Layout>
      <Seo
        title={`tác giả ${contentfulPoet.penName} - các bài thơ chọn lọc`}
        keywords={[contentfulPoet.penName]}
        description={`các bài thơ chọn lọc của tác giả ${contentfulPoet.penName}`}
      />
      <h1 className="font-medium text-2xl sm:text-3xl">
        {contentfulPoet.penName}
      </h1>
      <div>
        <h2 className="font-semibold italic mb-3">Các bài thơ chọn lọc:</h2>
        <div className="flex flex-col space-y-2">
          {allContentfulPoem.edges.map(({ node }) => (
            <div key={node.id}>
              <Link to={`/poems/${node.slug}`} className="hover:underline">
                {node.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <GoogleAdsense
        client="ca-pub-1804168523618722"
        slot="3767743010"
        className="google-ads-footer"
      />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulPoet(id: { eq: $id }) {
      penName
    }
    allContentfulPoem(
      filter: { poet: { id: { eq: $id } } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`
